import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { IoLogoLinkedin } from "react-icons/io5";

import { Container, Wrapper, Row, Box, Button } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Image from "../components/image";

import FlexiTimeTeam from "../images/staff/FlexiTime_ABV2021.jpg";

const TeamList = styled(Row)`
  --item-gap: 40px;
  align-items: stretch;

  width: calc(100% + var(--item-gap));

  > div {
    width: calc((100% / 2) - var(--item-gap));
    margin-right: var(--item-gap);
    margin-bottom: var(--item-gap);

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 100%;
      margin-right: 0;
    }
  }

  .icon {
    font-size: 35px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

const TeamCard = styled(Box)`
  color: black;
  border: solid 1px #ccc;

  .about-content {
    padding: 30px;

    svg {
      font-size: 2rem;
      color: #ffad3d;
    }
  }
`;

const About = (props) => {
  const team = [
    {
      pic: "RobertOwen.jpg",
      name: "Rob Owen",
      role: "CEO & Founder",
      description: [
        <p>
          Robert is a seasoned business owner and successful entrepreneur who
          has spent the last 15 years developing innovative software solutions
          that help businesses manage their workforce.
        </p>,
        <p>
          Prior to founding FlexiTime, Robert ran a business intelligence and
          web app development company in Wellington. When the GFC hit in 2008 he
          made the decision to pivot from services to Software as a Service and
          hasn’t looked back.
        </p>,
        <p>
          Robert is the visionary behind FlexiTime’s suite of products and is
          involved in the day to day operations as well as driving the business
          forward with big ideas and innovation.
        </p>,
      ],
      linkedin: "https://www.linkedin.com/in/robert-owen/",
    },
    {
      pic: "ShawniHadfield.jpg",
      name: "Shawni Hadfield",
      role: "Chief Financial Officer",
      description: [
        <p>
          ​​Shawni is a strategic finance leader with more than 15 years
          experience in leading finance functions and advising on investment,
          business strategy and M&A/corporate transactions. Her role at
          FlexiTime includes aspects of both finance and operations giving her
          the opportunity to help the business be successful and efficient
          across key areas.
        </p>,
        <p>
          Shawni began her career in professional services, across five
          different countries, working with and supporting businesses of many
          different types and sizes to set strategy, execute on financial
          decisions and streamline business operations to achieve success. She
          is a Chartered Accountant and a CFA charterholder.
        </p>,
      ],
      linkedin: "https://www.linkedin.com/in/shawni-hadfield-7b824612/",
    },
    {
      pic: "JakeHarvey.jpg",
      name: "Jake Harvey",
      role: "Chief Growth Officer",
      description: [
        <p>
          Jake is responsible for driving revenue growth, expanding our customer
          base and growing the value of our brands.
        </p>,
        <p>
          He has led high performing marketing and growth teams in both New
          Zealand and the United Kingdom and joined FlexiTime in 2014.
        </p>,
        <p>
          While initially hired to build out the marketing function, over the
          years his role at FlexiTime has encompassed a wide range of
          customer-facing and operational responsibilities. His extensive
          knowledge across the entire business ensures a strong customer focus
          is applied to strategic and executive decision making.
        </p>,
      ],
      linkedin: "https://www.linkedin.com/in/jakeharvey/",
    },
    {
      pic: "BrentSpicer.jpg",
      name: "Brent Spicer",
      role: "Director",
      description: [
        <p>
          Spice is a globally experienced business leader and board member. He
          is the co-founder and CEO of Marsello, a SaaS product that powers
          smart marketing for SME retailers and hospo businesses around the
          world.
        </p>,
        <p>Spice has been a director and adviser to FlexiTime since 2021.</p>,
      ],
      linkedin: "https://www.linkedin.com/in/brentspicer/",
    },
    {
      pic: "JillMcCorkindale.jpg",
      name: "Jill McCorkindale",
      role: "Director",
      description: [
        <p>
          Jill is an experienced technology leader who has been with FlexiTime
          since the very beginning. As well as being a company director, she’s
          also a key member of our customer success team and manages FlexiTime’s
          accounts.
        </p>,
        <p>
          Jill previously worked in leadership roles for Unisys and Cardinal
          Network, as well as IT consulting in the UK. She has a background in
          project management, development and implementation of business and
          telephony software.
        </p>,
      ],
      linkedin: "https://www.linkedin.com/in/jill-mccorkindale-1902a1258/",
    },
    {
      pic: "PeteAshby.jpg",
      name: "Pete Ashby",
      role: "Head of Partnerships",
      description: [
        <p>
          As Head of Partnerships, Peter manages FlexiTime’s extensive reseller
          network of accountants and bookkeepers, as well as strategic and
          technology partners.
        </p>,
        <p>
          Peter's experience includes holding various leadership positions in
          successful for-profit and social enterprises. He played a pivotal role
          at Trade Me, contributing significantly to the success of Trade Me
          Jobs. Since 2019, he has been an integral part of FlexiTime, bringing
          his extensive leadership expertise to the company.
        </p>,
      ],
      linkedin: "https://www.linkedin.com/in/peter-ashby-a891026/",
    },
  ];

  return (
    <Layout>
      <Seo
        title="The FlexiTime Team | About Us"
        description="Work. Life. Success.™ That's what makes us tick - and we’re committed to helping NZ business owners achieve all three. Find out about the team at FlexiTime."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper>
          <h2 className="h1 -fontPrimary">Meet the Team</h2>
          <TeamList justift="flex-start" isWrap>
            {team.map((item, i) => {
              return (
                <TeamCard>
                  <Image filename={item.pic} />
                  <Box stackGap={20} className="about-content">
                    <Box stackGap={5}>
                      <h2>{item.name}</h2>
                      <h4>{item.role}</h4>
                    </Box>
                    <p>{item.description}</p>
                    <a
                      href={item.linkedin}
                      target="_blank"
                      rel="noreferrer"
                      css={{ display: "table" }}
                    >
                      <IoLogoLinkedin />
                    </a>
                  </Box>
                </TeamCard>
              );
            })}
          </TeamList>
          <img src={FlexiTimeTeam} alt="FlexiTime Team 2021" />
        </Wrapper>
      </Container>
      <Container bg="lightGrey" className="-dark" graphicalBR>
        <Wrapper>
          <Box stackGap={30}>
            <h2 className="h1">Join the team</h2>
            <p className="h3 -fontNormal" css={{ color: "#000 !important" }}>
              We’re a company built on big ideas. Help us build a future where
              work is better for everyone.
            </p>
            <p className="h3 -fontNormal">
              <a href="https://flexitime.bamboohr.com/careers" target="_blank">
                Join Us
              </a>
            </p>
          </Box>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default About;
